
@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.organization-logo-text {
    color: $color-dark;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 40vw;
    display: block;
    font-size: 16px;
}

.organization-logo {
    img {
        object-fit: contain;
        object-position: left center;
        height: 50px;
        width: 70px;
    }

    &.expand {
        img {
            height: 70px;
            width: 100px;

            @media (max-width: 300px) {
                width: 40vw;
            }
        }
    }

    &.horizontal {
        img {
            height: 50px;
            width: 38vw;
            width: min(150px, 38vw);
        }

        &.expand {
            img {
                height: 70px;
                width: 38vw;
            }
        }
    }
}
