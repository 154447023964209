
@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.cart-item-row {
    h3 {
        padding-top: 5px;
        @extend .style-title-3;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .description {
        @extend .style-description-small;
        padding-top: 5px;
        white-space: pre-wrap;
    }

    .price {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 600;
        color: $color-primary;
    }

    .amount {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 600;
        margin-right: 15px;
    }

    .discount-tags {
        padding-top: 5px;

        > .style-tag {
            margin-left: 5px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .style-discount-old-price {
        text-decoration: line-through;
        color: $color-gray-4;
    }

    .style-discount-price {
        color: $color-discount;
        margin-left: 5px;
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    figure {
        width: 70px;

        @media (min-width: 340px) {
            width: 80px;
        }

        @media (min-width: 801px) {
            width: 100px;
        }

        img {
            border-radius: $border-radius;
        }
    }
}

