
@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.seat-selection-box {
    margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
    position: relative;
    overflow-x: scroll;
    
    // Fixes drawing issues
    transform: translate3d(0, 0, 0);

    .padding-container {
        // Padding should be here (otherwise won't be included in scollable area)
        padding: 15px calc(45px + var(--st-horizontal-padding));
        padding-bottom: 30px;
        width: var(--sw);
        margin: 0 auto;
        box-sizing: content-box;

        @media (max-width: 800px) {
            padding: 15px calc(25px + var(--st-horizontal-padding));
        }
    }

    .row-label {
        position: absolute;
        top: calc(var(--ry));
        height: var(--rh);
        line-height: var(--rh);
        font-size: 12px;
        @extend .style-description;

        &.left {
            left: -45px;

            @media (max-width: 800px) {
                left: -30px;
            }
        }

        &.right {
            right: -45px;
            text-align: right;

            @media (max-width: 800px) {
                right: -30px;
            }
        }
    }

    .seating-plan-seats {
        position: relative;
        width: var(--sw);
        height: var(--sh);
    }


    .seat {
        position: absolute;
        display: block;
        left: var(--x);
        top: var(--y);
        width: var(--w);
        height: var(--h);
        box-sizing: border-box;
        border-radius: 5px;
        @extend .style-interactive-small;
        line-height: var(--h);
        color: $color-dark;
        color: var(--color, $color-dark);

        &.disabledPerson > .nr {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            font-size: 10px;
            text-align: left;
            line-height: 15px;
            height: 15px;
            padding: 2px;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            border-radius: 5px;

            border: $border-width solid $color-border;
            background: $color-background;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

            transition: background-color 0.2s, transform 0.2s;
            transform: scale(1, 1);
            animation-fill-mode: forwards;
        }

        .nr {
            opacity: 0.5;
        }
        &.space {
            font-size: 16px;
            color: $color-gray-text;
            font-weight: $font-weight-default;

            &::after {
                display: none;
            }

            .nr {
                opacity: 1;
            }

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }

            &:first-child:last-child {
                text-align: center;
            }
        }

        &.highlighted {
            font-weight: bold;
            color: $color-background;

            &::after {
                background: $color-primary-gray-light;
                border-color: $color-primary-gray-light;
            }

            .nr {
                opacity: 1;
            }
        }

        &.selected {
            color: $color-primary-contrast; 
            font-weight: bold;

            &::after {
                background: $color-primary;
                animation: increase 0.2s;
                border-color: $color-primary;
                
                background: var(--color, $color-primary);
                border-color: var(--color, $color-primary);
            }

            .nr {
                opacity: 1;
            }

            &.hasHighlights {
                z-index: 1;

                &::after {
                    animation: 0.6s ease 0.4s bringToAttention;
                }
            }
        }

        &.occupied {
            .canSelect & {
                cursor: not-allowed;
            }

             .nr {
                opacity: 0.2;
            }


            &::after {
                background: $color-border;
            }

            
        }
    }

    &.can-select .seat {
        touch-action: manipulation;
        user-select: none;
        cursor: pointer;
    }

    .seat > .icon {
        position: absolute;
        left: calc((var(--w) - 18px) / 2 + 3px);
        top: calc((var(--h) - 18px) / 2 + 2px);
        z-index: 0;
        font-size: 18px;

        &:before {
            font-size: 18px;
        }
    }
}

@keyframes bringToAttention {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1.8, 1.8);
        border-radius: 10px;
        background-color: $color-primary-dark;
    }
    50% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(2, 2);
        border-radius: 10px;
        background-color: $color-primary-dark;
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes increase {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.3, 1.3);
        border-radius: 8px;
    }
    100% {
        transform: scale(1, 1);
    }
}

