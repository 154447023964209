
@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.product-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    transition: background-color 0.2s 0.1s;

    margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
    padding-right: var(--st-horizontal-padding, 15px);

    .maskingSvg {
        display: none;
    }

    > .content > hr {
        border: 0;
        outline: 0;
        height: $border-width;
        background: $color-border;
        border-radius: calc($border-width / 2);
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: calc(-1 * var(--st-horizontal-padding, 15px));
    }

    &:last-child {
        > .content > hr {
            display: none;
        }
    }

    &:active {
        transition: none;
        background: $color-primary-background;
    }

    > .left {
        overflow: hidden;
        width: 4px;
        flex-shrink: 0;
        background: $color-primary;
        align-self: stretch;
        border-radius: $border-radius;
        opacity: 0;
        transform: translateX(-4px);
        transition: opacity 0.2s, transform 0.2s;
        margin-right: var(--st-horizontal-padding, 15px);
    }

    &.selected {
        > .left {
            opacity: 1;
            transform: translateX(0);
        }
    }

    > .content {
        flex-grow: 1;
        min-width: 0;
        align-self: stretch;
        display: flex;
        align-items: center;
        position: relative;

        > div {
            padding: 15px 0;

            flex-grow: 1;
            min-width: 0;

            > h3 {
                padding-top: 5px;
                @extend .style-title-3;
                line-height: 1.3;
                padding-right: 30px;
                position: relative;
                transition: transform 0.2s;

                > .counter {
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    width: 30px;

                    font-size: 14px;
                    line-height: 1.4;
                    font-weight: 600;
                    color: $color-primary;

                    transform: translateX(-30px);
                    transition: opacity 0.2s;
                    white-space: nowrap;
                }
            }

            > .description {
                @extend .style-description-small;
                padding-top: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                white-space: pre-wrap;
                line-clamp: 2; /* number of lines to show */
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;

                + .description {
                    padding-top: 0;
                }
            }

            > .price {
                font-size: 14px;
                line-height: 1.4;
                font-weight: 600;
                padding-top: 10px;
                color: $color-primary;
                display: flex;
                flex-direction: row;

                .style-tag {
                    margin-left: auto;
                }
            }
        }
        
    }

    &.ticket {
        position: relative;
    }


    &.selected {
        > .content > div {
            > h3 {
                transform: translateX(30px);

                >.counter {
                    opacity: 1;
                }
            }
        }
    }

    figure {
        flex-shrink: 0;
        padding: 15px 0 15px 15px;

        img {
            //width: auto; // breaks layout shift on load
            height: auto;
            max-width: 70px;
            // max-height: 70px; // breaks aspect ratio
            border-radius: $border-radius;
            display: block;

            @media (min-width: 340px) {
                max-width: 80px;
                // max-height: 80px; // breaks aspect ratio
            }
        }
    }
}

.enable-grid .product-box {
    @media (min-width: 801px) {
        background: $color-background;
        border-radius: $border-radius;
        margin: 0;
        
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 20px 50px $color-shadow, inset 0px 0px 0px 1px $color-shadow;
        overflow: hidden;

        padding-right: 0;

        &:active {
            transition: none;
            background: $color-primary-background;
        }

        > .content > hr {
            display: none;
        }

        > .left {
            margin-right: 0;
        }

        > .content > div {
            padding: 15px;
        }

        figure {
            padding: 15px 15px 15px 15px;

            img {
                // width: auto; // breaks layout shift on load
                height: auto;
                max-width: 100px;
                // max-height: 100px; // breaks aspect ratio
            }
        }

        &.ticket {
            overflow: visible;

            .maskingSvg {
                display: block;
                z-index: -1;
                position: absolute;
                height: 100%;
                width: 100%;
            }
            
            background: none;

            // Force hardware acceleration (for filter)
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            position: relative;
            z-index: 1;

            filter: drop-shadow($color-shadow 0px 20px 50px) drop-shadow(rgba(0, 0, 0, 0.05) 0px 4px 4px);
            box-shadow: none;

            > .left {
                order: 30;
                transform: translateX(4px);
            }

            &.selected > .left {
                transform: translateX(0px);
            }

            > .content > div {
                padding-left: 30px;
                padding-top: 30px;
                padding-bottom: 30px;
            }

            .svg-background {
                transition: fill 0.2s 0.1s;
                fill: $color-background;
            }

            &:active {
                background: none;

                .svg-background {
                    transition: none;
                    fill: $color-primary-background;
                }
            }
        }
    }
}

